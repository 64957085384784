var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canRender
    ? _c("ae-floating-cart", {
        on: {
          toCheckout: function($event) {
            return _vm.toCheckout()
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }