var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column align-items-center" },
    [
      _c("div", { staticClass: "pos-selector-title mb-2" }, [
        _vm._v("Selecione a unidade")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pos-selector-subtitle mb-4" }, [
        _vm._v(
          "Antes visualizar os pacotes, informe onde você deseja realizar nossos serviços."
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap justify-content-center" },
        [
          _vm._l(_vm.posList, function(item, key) {
            return [
              _c(
                "button",
                {
                  key: key,
                  staticClass: "btn m-1",
                  class: {
                    "btn-primary": _vm.isSelected(item.id),
                    "btn-outline-primary": !_vm.isSelected(item.id)
                  },
                  on: {
                    click: function($event) {
                      return _vm.onSelect(item.id)
                    }
                  }
                },
                [_vm._v(_vm._s(item.short_description))]
              )
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.posIdSelected
        ? [_vm._t("default", null, { posId: _vm.posIdSelected })]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }