<template>
  <div>

    <ModalPackageInfo/>

    <PosSelector>
      <template slot-scope="{ posId }">
        <FloatingCart />

        <GenreSelector class="mt-4">
          <template slot-scope="{ genre }">
            <Packages
              class="d-flex justify-content-center mt-4"
              :config="{ posId: posId, tag: genre }"
            />
          </template>
        </GenreSelector>
      </template>
    </PosSelector>

    

  </div>
</template>

<style>
  @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");

  body{
    overflow-x: hidden;
  }
  body .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
    
  }

</style>

<script>
import FloatingCart from './floating-cart.vue';
import Packages from './packages.vue';
import PosSelector from './pos-selector.vue';
import GenreSelector from './genre-selector.vue';
import ModalPackageInfo from './modal-package-info.vue';

export default {
  components: {
    FloatingCart,
    Packages,
    PosSelector,
    GenreSelector,
    ModalPackageInfo,
  }
};
</script>