<template>
  <ae-floating-cart v-if="canRender" @toCheckout="toCheckout()"></ae-floating-cart>
</template>

<style lang="scss">
.ae-floating-cart {
  z-index: 1;
}
</style>

<script>
export default {
  data() {
    return {
      canRender: false
    };
  },
  mounted() {
    this.canRender = true;
  },
  methods: {
    toCheckout() {
      window.AngularEcommerce.DudaWidgets.fns.showCheckoutModal();
    }
  }
};
</script>