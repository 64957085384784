var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canRender
    ? _c("ae-package-list", {
        domProps: {
          template: "pacotes",
          posId: _vm.config.posId,
          params: _vm.params
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }