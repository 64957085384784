<template>
  <div class="row mt-4">
    <div class="col-12 text-center">
      <h4>Meus Agendamentos</h4>
    </div>

    <div class="col-12" v-if="schedules.length > 0 && !loading">
      <div v-for="(item, i) in schedules" :key="`listCard${i}`" class="col-12">
        <div class="card card-list mt-3">
          <div class="p-2">
            <div class="d-flex justify-content-between text-secondary align-items-center">
              <div class="ml-3">
                <div>
                  <strong>{{ item.template_service_info? item.template_service_info.name : '' }}</strong>
                </div>
                <div>
                  {{ returnDate(item.event_info.event_date) }} às
                  {{ returnHorsF(item.start_time) }}
                </div>
                <!-- <div>Andreia</div> -->
              </div>
              <div
                @click="openConfirmDelete(item)"
                class="btn-remove d-flex justify-content-center align-items-center"
              >
                Cancelar
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="col-12 text-center">
      <div v-if="loading" class="loader mt-3"></div>
      <div v-else class="alert alert-light new-alert" role="alert">
        Não há agendamentos até o momento para esta unidade
      </div>
    </div>

    <div
      v-if="deleteModal"
      class="delete-modal-box d-flex justify-content-center align-items-center"
    >
      <div class="delete-modal d-flex flex-column justify-content-center align-items-center">
        <div @click="closeModal" class="btn-red d-flex justify-content-center align-items-center">
          X
        </div>
        <span style="color: #c01828">
          <i class="fas fa-exclamation-circle fa-3x"></i>
        </span>
        <h5 style="color: #721c24" class="mt-2 text-center">
          <strong>Cancelamento de serviço</strong>
        </h5>
        <div class="divider mt-1 mb-2"></div>
        <div class="text-center" style="color: #721c24">
          Deseja realmente cancelar o serviço
          <strong>{{ eventSelect.template_service_info.name }}</strong
          >?
        </div>
        <div
          @click="cancelEvent"
          class="btn-success mt-2 d-flex justify-content-center align-items-center"
        >
          Confirmar
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'list-schedule',
  props: {
    idUnit: Number
  },
  data() {
    return {
      loading: false,
      deleteModal: false,
      schedules: [],
      eventSelect: {}
    };
  },
  watch: {
    idUnit(val) {
      if (val) this.allSchedules();
    }
  },
  mounted() {
    this.allSchedules();
  },
  methods: {
    returnHors(h) {
      return h.slice(0, 5);
    },
    returnHorsF(h) {
      return moment(h).format('HH:mm');
    },
    returnDate(item) {
      return moment(item).format('DD/MM/YYYY');
    },
    openConfirmDelete(item) {
      this.eventSelect = item;
      this.deleteModal = true;
    },
    closeModal() {
      this.deleteModal = false;
      this.eventSelect = {};
    },
    allSchedules() {
      this.loading = true;
      return new Promise(resolve => {
        let results = [];
        const paginator = window.AngularEcommerce.ws.eventBooks.getAll({
          params: {
            user_id: window.AngularEcommerce.web.stores.getters['auth/user'].id,
            status: 'booked',
            event_date_from: moment().format('YYYY-MM-DD'),
            event_unit_list: this.idUnit,
            sort: 'start_time'
          }
        });
        return paginator.getAllPages(({ data }) => {
          results.push(...data.results);

          if (!paginator.hasNextPage()) {
            resolve(results);
            this.loading = false;
            this.schedules = results;
          }
        });
        /*  .catch(() => {
            this.loading = false;
          }); */
      });
    },
    cancelEvent() {
      this.alert = false;
      return new Promise((resolve, reject) => {
        window.AngularEcommerce.ws.eventBooks
          .cancel(this.eventSelect.token)
          .then(() => {
            resolve();
          })
          .catch(err => {
            let msgErr = 'Não foi possivel cancelar o agendamento';
            if (err.response.data.detail) {
              msgErr = err.response.data.detail;
            }
            reject({ message: msgErr });
          });
      })
        .then(() => {
          this.closeModal();
          this.allSchedules();
          this.$emit('reload');
          this.$notify({
            text: 'Agendamento cancelado com sucesso',
            type: 'success',
            duration: 4000
          });
        })
        .catch(err => {
          this.closeModal();
          this.$notify({
            text: err.message,
            type: 'error',
            duration: 4000
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.divider {
  width: 100%;
  height: 1px;
  background-color: grey;
}

.delete-modal-box {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba($color: #000000, $alpha: 0.3);
  z-index: 1000;

  .delete-modal {
    min-width: 300px;
    max-width: 600px;
    padding: 12px;
    background-color: white;
    border-radius: 5px;

    margin: 0px 18px 0px 18px;

    position: absolute;
  }
}

.btn-red {
  background-color: #d41a2a;
  color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-weight: bold;
  padding: 5px 10px;
  position: absolute;
  top: -19px;
  right: -14.5px;
  text-decoration: none;
  cursor: pointer;
}

.btn-red:hover {
  background-color: #b31824;
}

.btn-remove {
  border-radius: 20px;
  background-color: #d81b1b;
  padding: 5px 10px;
  font-size: 13px;
  color: white;
  cursor: pointer;
}

.btn-remove:hover {
  background-color: #b31824;
}

.btn-success {
  border-radius: 20px;
  background-color: #3fb6a7;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
}

.apply-responsivity {
  @media screen and (max-width: 576px) {
    margin-top: 14px;
  }
}
</style>
