<template>
  <div class="calendar-modal-box z-1000 d-flex justify-content-center align-items-center">
    <div
      v-if="step === 1"
      class="calendar-modal-content d-flex flex-column align-items-center position-absolute"
    >
      <div
        @click="closeModal"
        class="btn-to-modal btn-red d-flex justify-content-center align-items-center"
      >
        X
      </div>
      <h4 class="text-center text-secondary">
        Escolha seu horário
        <!--  <div class="btn-close-dialog">
          <button @click="closeModal" type="button" class="btn btn-danger"><div>X</div></button>
        </div> -->
      </h4>
      <!--  <div class="divider w-100 mt-2 mb-3"></div> -->
      <div class="">
        <the-mask
          @keypress.native.enter="setStep(2)"
          size="4"
          v-model="dateTime"
          masked="true"
          :disabled="disableInput"
          class="form-control"
          mask="##:##"
          placeholder="Horário"
        />
      </div>

      <div
        v-if="eventGenerator.allow_customer_choose_time"
        class="w-100 alert alert-warning mt-3 mb-0"
        role="alert"
      >
        Selecione um horário entre <strong>{{ returnHors(free_time.time_from) }}</strong> e
        <strong>{{ returnHors(free_time.time_to) }}</strong>
      </div>
      <div v-if="alert" class="alert alert-danger w-100 mt-3 mb-0" role="alert">
        Você <strong>precisa</strong> preencher o campo acima para continuar a ação
      </div>
      <div class="w-100 mt-3 d-flex justify-content-center">
        <div @click="setStep(2)" class="btn-to-modal btn-green ml-3">Seleção dos serviços</div>
      </div>
    </div>

    <div v-if="step === 2" class="calendar-modal-content d-flex flex-column align-items-center">
      <div
        @click="closeModal"
        class="btn-to-modal btn-red d-flex justify-content-center align-items-center"
      >
        X
      </div>
      <h4 class="text-center text-secondary">Personalize seu atendimento</h4>
      <p class="text-secondary">Selecione as regiões do corpo que deseja atendimento</p>

      <div style="border: 1px solid #308378" class="w-100 mt-2 mb-3"></div>

      <div
        v-if="loading"
        style="width: 3rem; height: 3rem; color: #308378;"
        class="spinner-border"
        role="status"
      ></div>

      <!-- <div class="divider-bar w-100 mt-3 mb-3"></div> -->
      <div v-if="!loading" class="form-check w-100">
        <div class="container">
          <div class="row d-flex justify-content-center align-items-center">
            <div
              v-for="(local, i) in locals"
              :key="`local${i}`"
              class="col-12 col-sm-6 col-md-4 my-2 d-flex justify-content-center"
            >
              <card-local
                ref="cardBody"
                :check="filterBoryArea(local.id)"
                :local="local"
                @insertLocal="insertLocal"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="alert" class="w-100 alert alert-danger mt-3 mb-0" role="alert">
        Você <strong>precisa</strong> selecionar pelo menos uma região do corpo
      </div>
      <div v-if="alert" style="border: 1px solid #308378" class="w-100 mt-2"></div>
      <div v-if="!loading" class="w-100 mt-3 d-flex justify-content-center position-relative">
        <div @click="setStep(1)" class="btn-to-modal btn-yellow">Voltar</div>
        <div
          v-if="localsSelected.length > 0"
          @click="saveLocals"
          class="btn-to-modal btn-green ml-2"
        >
          Concluir
        </div>
      </div>
    </div>

    <div
      v-if="step === 3"
      class="calendar-modal-content d-flex flex-column align-items-center position-absolute"
    >
      <!---  <div
          @click="closeModal"
          class="btn-to-modal btn-red d-flex justify-content-center align-items-center"
        >
          X
        </div> -->

      <div
        v-if="alertSuccess"
        class="alert alert-success my-3 w-100 d-flex justify-content-center align-items-center flex-column"
        role="alert"
      >
        <i class="fas fa-check fa-3x"></i>
        <div class="mt-2"><strong>Parabéns!</strong></div>
        <div class="green-divider mt-2 mb-2"></div>
        <div>
          Seu agendamento foi concluído com sucesso para o dia {{ returnDateSchedulign() }}.
        </div>
      </div>

      <div
        v-else
        class="alert alert-danger my-3 w-100 d-flex justify-content-center align-items-center flex-column"
        role="alert"
      >
        <i class="fas fa-exclamation-circle fa-3x"></i>
        <div class="mt-2"><strong>Sentimos muito!</strong></div>
        <div>Seu agendamento não pôde ser concluído!</div>
        <div class="red-divider mt-2 mb-2"></div>
        <div>{{ messageError }}</div>
      </div>

      <div v-if="localsSelected.length > 0" @click="closeModal" class="btn-to-modal btn-green ml-2">
        Fechar
      </div>
    </div>
  </div>
</template>

<script>
/*
or for SSR:
import Notifications from 'vue-notification/dist/ssr.js'
*/
import { TheMask } from 'vue-the-mask';

import CardLocal from '../cardBodyArea/CardBodyArea.vue';
import moment from 'moment';
export default {
  props: {
    locals: Array,
    free_time: Object,
    eventGenerator: Object
  },
  components: {
    CardLocal,
    TheMask
  },
  name: 'schedule-modal',
  data() {
    return {
      loading: false,
      alert: false,
      alertSuccess: false,
      dateTime: '',
      messageError: '',
      step: 1,
      localsSelected: []
    };
  },
  mounted() {
    this.dateTime = this.returnHors(this.free_time.time_from);
  },
  computed: {
    OptimizeTime() {
      if (
        window.AngularEcommerce.web.stores.getters['auth/user'] &&
        window.AngularEcommerce.web.stores.getters['auth/user'].is_staff
      ) {
        if (this.eventGenerator.allow_staff_choose_time) {
          if (this.dateTime != this.returnHors(this.free_time.time_from)) return false;
          else return true;
        } else {
          return true;
        }
      } else {
        if (this.eventGenerator.allow_customer_choose_time) {
          if (this.dateTime != this.returnHors(this.free_time.time_from)) return false;
          else return true;
        } else {
          return true;
        }
      }
    },
    disableInput() {
      if (
        window.AngularEcommerce.web.stores.getters['auth/user'] &&
        window.AngularEcommerce.web.stores.getters['auth/user'].is_staff
      ) {
        if (this.eventGenerator.allow_staff_choose_time) return false;
        else return true;
      } else {
        if (this.eventGenerator.allow_customer_choose_time) return false;
        else return false;
      }
    }
  },
  methods: {
    returnHors(h) {
      return h.slice(0, 5);
    },
    filterBoryArea(val) {
      let filterBory = this.localsSelected.filter(item => {
        return item === val;
      });

      if (filterBory && filterBory.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    insertLocal(val) {
      let verify = false;
      let index = 0;
      for (let i = 0; i < this.localsSelected.length; i++) {
        if (val === this.localsSelected[i]) {
          index = index + i;
          verify = true;
        }
      }
      if (verify) {
        this.localsSelected.splice(index, 1);
      } else {
        this.localsSelected.push(val);
      }
    },
    saveLocals() {
      if (this.localsSelected.length > 0) {
        this.saveGeneration();
      }
    },
    setStep(val) {
      if (val === 2) {
        if (this.dateTime) {
          this.alert = false;
          this.step = val;
        } else {
          this.alert = true;
        }
      } else {
        this.alert = false;
        this.step = val;
      }
    },
    closeModal() {
      this.alert = false;
      this.dateTime = '';
      if (this.step === 3) {
        this.$emit('reload');
      }
      this.step = 1;
      this.$emit('closeModal', false);
    },
    returnDateSchedulign() {
      return moment(this.eventGenerator.date_select).format('DD/MM/YYYY') + ' às ' + this.dateTime;
    },
    saveGeneration() {
      this.loading = true;
      this.alert = false;
      return new Promise((resolve, reject) => {
        // verifica se tem usuario na store do angularecommerce
        if (
          !window.AngularEcommerce.web.stores.getters['auth/isLogged'] &&
          !window.AngularEcommerce.web.stores.getters['auth/user']
        ) {
          throw new Error('Usuário não encontrado!');
        }

        window.AngularEcommerce.ws.events
          .book(this.eventGenerator.token, {
            user: window.AngularEcommerce.web.stores.getters['auth/user'].id,
            room: 1,
            event_templates: this.localsSelected,
            booking_time:
              moment(this.eventGenerator.date_select).format('YYYY-MM-DD') + ' ' + this.dateTime,
            //is_video: false,
            optimize_time: false //this.OptimizeTime
          })
          .then(() => {
            resolve();
          })
          .catch(err => {
            let msgErr = 'Não foi possivel finalizar o agendamento';
            if (err.response.data.detail) {
              msgErr = err.response.data.detail;
            }
            if (err.response.data.non_field_errors) {
              msgErr = err.response.data.non_field_errors[0];
            }
            this.messageError = msgErr;
            reject({ message: msgErr });
          });
      })
        .then(() => {
          this.loading = false;
          this.alertSuccess = true;
          this.setStep(3);

          //this.closeModal();
        })
        .catch(() => {
          this.loading = false;
          this.setStep(3);
          this.$notify({
            text: 'Não foi possivel finalizar Agendamento',
            type: 'error',
            duration: 4000
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import './scss/scheduleModal.scss';
</style>
