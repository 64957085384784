var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ModalPackageInfo"),
      _vm._v(" "),
      _c("PosSelector", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var posId = ref.posId
              return [
                _c("FloatingCart"),
                _vm._v(" "),
                _c("GenreSelector", {
                  staticClass: "mt-4",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var genre = ref.genre
                          return [
                            _c("Packages", {
                              staticClass: "d-flex justify-content-center mt-4",
                              attrs: { config: { posId: posId, tag: genre } }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }