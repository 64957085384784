var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c("div", { staticClass: "genre-selector-title text-center mb-2" }, [
        _vm._v("Depilação para")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-center" }, [
        _c(
          "button",
          {
            staticClass: "btn m-1",
            class: {
              "btn-primary": _vm.selected === _vm.FEMALE_TAG,
              "btn-outline-primary": _vm.selected !== _vm.FEMALE_TAG
            },
            on: {
              click: function($event) {
                return _vm.onSelect(_vm.FEMALE_TAG)
              }
            }
          },
          [_vm._v("Mulher")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn m-1",
            class: {
              "btn-primary": _vm.selected == _vm.MALE_TAG,
              "btn-outline-primary": _vm.selected !== _vm.MALE_TAG
            },
            on: {
              click: function($event) {
                return _vm.onSelect(_vm.MALE_TAG)
              }
            }
          },
          [_vm._v("Homem")]
        )
      ]),
      _vm._v(" "),
      _vm._t("default", null, { genre: _vm.selected })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }