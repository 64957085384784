var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "card cardEvent" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between text-secondary align-items-center mt-2 pr-4 pl-4"
          },
          [
            _c("div", { staticClass: "title-card" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.data.name) + "\n        \n        "
              ),
              _vm.data.description || _vm.data.tagline
                ? _c("div", { staticClass: "popover__wrapper" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "popover__content" }, [
                      _c("p", {
                        staticClass: "popover__message m-0",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.data.description || _vm.data.tagline
                          )
                        }
                      })
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                _vm._s(_vm.returnHors(_vm.data.start_time)) +
                  " às " +
                  _vm._s(_vm.returnHors(_vm.data.end_time))
              )
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "divider" }),
        _vm._v(" "),
        _c("div", { staticClass: "mt-2 pl-4 pr-4" }, [
          _vm.data && _vm.data.free_time.length > 0
            ? _c("p", { staticClass: "card-text text-secondary" }, [
                _vm._v("Horários livres em " + _vm._s(_vm.returnDate()))
              ])
            : _c("p", { staticClass: "card-text text-secondary" }, [
                _vm._v(_vm._s(_vm.returnDate()))
              ]),
          _vm._v(" "),
          _vm.data && _vm.data.free_time.length > 0
            ? _c(
                "div",
                _vm._l(_vm.data.free_time, function(item, i) {
                  return _c("div", { key: "free-time" + i }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between text-secondary align-items-center"
                      },
                      [
                        _c("div", { staticClass: "text-left text-secondary" }, [
                          _vm._v(
                            "\n              Das " +
                              _vm._s(_vm.returnHorsFormat(item.time_from)) +
                              " às " +
                              _vm._s(_vm.returnHorsFormat(item.time_to)) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "btn-to-schedule",
                            on: {
                              click: function($event) {
                                return _vm.openModal({
                                  time_from: item.time_from,
                                  time_to: item.time_to
                                })
                              }
                            }
                          },
                          [_vm._v("\n              Agendar\n            ")]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    i > _vm.data.free_time.length + 1
                      ? _c("div", { staticClass: "divider-small" })
                      : _vm._e()
                  ])
                }),
                0
              )
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between text-secondary align-items-center"
                },
                [
                  _c("div", { staticClass: "text-left text-secondary" }, [
                    _vm._v("Não há horários disponíveis!")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn-to-schedule-disabled" }, [
                    _vm._v("\n          Agendar\n        ")
                  ])
                ]
              )
        ])
      ]),
      _vm._v(" "),
      _vm.modalOn
        ? _c("schedule-modal", {
            attrs: {
              locals: _vm.bodyAreas,
              free_time: _vm.current_free_time,
              eventGenerator: Object.assign({}, _vm.data, {
                date_select: this.date
              })
            },
            on: { closeModal: _vm.closeModal, reload: _vm.reload }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "bi bi-info-circle popover__title" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }