<template>
  <div class="container">
    <div class="fade-in mt-5" v-if="e === 1">
      <!--   <div class="he-100 d-flex justify-content-center align-items-center"> -->
      <!--   <div class=" w-100 d-flex flex-column align-items-center"> -->
      <div class="pos-selector-title color-text-theme mb-2">
        Onde você deseja realizar nossos serviços ?
      </div>

      <div class="row mt-5">
        <div class="col-12 col-sm-6">
          <span class="pos-selector-subtitle mb-1">Selecione a cidade</span>
          <v-select
            style="width: 100%;"
            :options="locationList"
            v-model="currentLocation"
            label="name"
            @input="selectLocation"
          >
          </v-select>
        </div>

        <div class="col-12  col-sm-6 mt-4 mt-sm-0">
          <span class="pos-selector-subtitle mb-1">Selecione a unidade</span>
          <v-select
            :disabled="!currentLocation"
            style="width: 100%;"
            :options="unitList"
            v-model="currenUnit"
            label="name"
            @input="selectUnit"
          >
          </v-select>
        </div>
      </div>
    </div>
    <div class="fade-in" v-if="e === 2">
      <div class="row mt-4 h-100 justify-content-center">
        <div class="col-12 col-sm-6 text-center text-sm-left">
          <h5>Unidade {{ currenUnit.name }}</h5>
        </div>
        <div class="col-12 col-sm-6 text-right">
          <button
            @click="rolbackSelect"
            type="button"
            class="btn color-bg-theme color-text-theme mt-4 mt-sm-0 mb-4 btn-outline-primary btn-sm"
          >
            Selecionar outra unidade
          </button>
        </div>
        <div class="col-12 col-sm-6 mt-3 text-center">
          <DatePicker
            v-model="date"
            locale="pt-br"
            color="teal"
            :min-date="new Date()"
            :max-date="maxDate"
            @input="getAllEvents()"
            is-expanded
            is-required
          />
        </div>

        <!--:disabled-dates="{ weekdays: [1, 7] }"  :max-date="maxDate()"-->

        <div class="col-12 mt-4 mt-sm-0 col-sm-6 text-center">
          <div v-if="arrayEvent.length > 0 && !loading">
            <div class="pt-3" v-for="(item, i) in arrayEvent" :key="`event${i}`">
              <EventGenerators
                @reload="reload"
                @openModal="openModal"
                :data="item"
                :date="dateSelect"
                @isLoadingStatus="isLoadingStatus"
              />
            </div>
          </div>

          <div v-else class="text-center">
            <div v-if="loading" class="loader mt-3"></div>
            <div v-else class="alert alert-light new-alert" role="alert">
              Selecione uma data no calendário para ver os horários disponíveis
            </div>
          </div>
        </div>
      </div>

      <ListSchedule v-if="user!=null" @reload="reload" :idUnit="currenUnit.id" ref="sheduleList" />
    </div>
  </div>
</template>

<script>
const STORAGE_KEY = 'unit_id';

import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import EventGenerators from './elements/eventGenerationCard/EventGeneration.vue';
import ListSchedule from './elements/listSchedule/listSchedule.vue';

import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from 'moment';

export default {
  name: 'calendar-monthly',
  components: {
    DatePicker,
    EventGenerators,
    ListSchedule,
    VSelect
  },
  data() {
    return {
      e: 1,
      unitList: [],
      locationList: [],
      currentLocation: '',
      currenUnit: '',
      alert: false,
      dateTime: '',
      loading: false,
      isLoading: false,
      date: new Date(),
      dateSelect: '',
      days: [],
      arrayEvent: [],
      selectedDate: null,
      user: window.AngularEcommerce.web.stores.getters['auth/user'],
      config: null
    };
  },
  created() {
    this.initSettings();
    this.getLacations().then(resLocation => {
      this.locationList = resLocation;
    });
    // if (window.localStorage.getItem(STORAGE_KEY)) {
    //   //this.currenUnit = {};
    //   this.currenUnit.id = Number(window.localStorage.getItem(STORAGE_KEY));
    // }else{
    //   this.currenUnit = '';
    // }
  },
  watch: {
    /*  date(val) {
      this.getAllEvents();
    } */
  },
  computed: {
    maxDate() {
      if (window.AngularEcommerce.web.stores.getters['auth/user']) {
        return window.AngularEcommerce.web.stores.getters['auth/user'].schedule_visibility;
      } else {
        return moment()
          .add(this.config.events.schedule_visibility, 'days')
          .format('YYYY-MM-DD');
      }
    }
  },
  methods: {
    selectLocation() {
      this.getUnits().then(respUnit => {
        this.unitList = respUnit;
      });
    },
    selectUnit() {
      window.localStorage.setItem(STORAGE_KEY, this.currenUnit.id);
      this.getAllEvents(true);
    },
    getLacations() {
      return new Promise(resolve => {
        const results = [];

        const paginator = window.AngularEcommerce.ws.locations.getAll({
          params: {
            activity_list: 1,
            use_as_filter: true,
            enabled: true
          }
        });

        paginator.getAllPages(({ data }) => {
          results.push(...data.results);

          if (!paginator.hasNextPage()) {
            resolve(results);
          }
        });
      });
    },
    getUnits() {
      return new Promise(resolve => {
        const results = [];

        const paginator = window.AngularEcommerce.ws.units.getAll({
          params: {
            //id_list: '',
            location_list: this.currentLocation.id,
            activity_list: 1,
            enabled: true,
            is_online: true
          }
        });

        paginator.getAllPages(({ data }) => {
          results.push(...data.results);

          if (!paginator.hasNextPage()) {
            resolve(results);
          }
        });
      });
    },
    rolbackSelect() {
      this.currenUnit = '';
      this.nextPage(1);
    },
    nextPage(step) {
      setTimeout(() => {
        this.e = 1000;
      }, 200);
      setTimeout(() => {
        this.e = step;
      }, 500);
    },
    initSettings() {
      window.AngularEcommerce.ws.settings.get().then(resp => {
        this.config = resp.data;
      });
    },
    reload() {
      this.user = window.AngularEcommerce.web.stores.getters['auth/user'];
      this.getAllEvents();
      this.$refs.sheduleList.allSchedules();
    },
    isLoadingStatus(status) {
      this.isLoading = status;
    },
    getAllEvents(load) {
      this.loading = true;
      return new Promise(resolve => {
        let date = moment(this.date).format('YYYY-MM-DD');

        var dataParams = {
          date,
          activity_list: 1,
          unit_list: this.currenUnit.id,
          booking_strategy: 'appointment',
          sort: 'start_time'
        };

        //console.log('user====logado===', JSON.stringify(window.AngularEcommerce.web.stores.getters['auth/user'], null, 4))

        if (window.AngularEcommerce.web.stores.getters['auth/user']) {
          dataParams.user_id = window.AngularEcommerce.web.stores.getters['auth/user'].id;
        }

        let results = [];
        const paginator = window.AngularEcommerce.ws.events.getAll({
          params: {
            ...dataParams,
            show_in_schedule: true
          }
        });
        paginator.getAllPages(({ data }) => {
          results.push(...data.results);
          if (!paginator.hasNextPage()) {
            resolve(results);
            this.dateSelect = this.date;
            this.loading = false;
            this.arrayEvent = results;
            if (load) {
              this.nextPage(2);
            }
          }
        });
      });
    }
  }
};
</script>

<style lang="scss">
.he-100 {
  height: 100vh;
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}
.z-1000 {
  z-index: 1000;
}

.z-100 {
  z-index: 100;
}

.divider-bar {
  background-color: #808080;
  height: 1px;
}

.calendar-modal-box {
  background: rgba($color: #000000, $alpha: 0.3);
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  position: absolute;

  .calendar-modal-content {
    border-radius: 5px;
    background: white;
    padding: 12px;

    position: absolute;

    min-width: 310px;
    width: 500px;
    max-width: 900px;

    .btn-close-dialog {
      position: absolute;
      right: 0px;
      top: -45px;
      font-weight: bold;
      color: white;
    }

    .title-dialog {
      font-weight: 600;
      font-size: 16px;
    }

    .button-schedule {
      width: 120px;
      color: white;
      border-radius: 2em;
      font-weight: bold;
      background-color: #3fb6a7;
      border: none;
      box-shadow: 0px 0px 8px #3fb6a7;
    }

    .button-back {
      width: 120px;
      color: white;
      border-radius: 2em;
      font-weight: bold;
      background-color: #e7b11d;
      border: none;
      box-shadow: 0px 0px 8px #e7b11d;
    }

    .button-close {
      width: 120px;
      color: white;
      border-radius: 2em;
      font-weight: bold;
      background-color: #e21527;
      border: none;
      box-shadow: 0px 0px 8px #e21527;
    }
  }
}

.ae-floating-cart {
  z-index: 1;
}
.card-period {
  transition: all 0.2s ease-in-out;
}

.card-period:hover {
  cursor: pointer;
  filter: brightness(90%);
}
.calendar {
  background-color: red;
}

.new-alert {
  background-color: #308378;
  color: white;
  border-radius: 10px;
}

.color-text-theme {
  color: #308378;
}

.color-bg-theme {
  border-color: #308378;
}

.color-bg-theme:hover {
  background-color: #308378;
  color: white;
}

.loader {
  margin-left: auto;
  margin-right: auto;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #308378;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}


/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Animations

.fade-in {
  opacity: 1;
  animation: fade 0.8s linear;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
