var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-freshen",
      staticStyle: {
        "background-color": "#00000022",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        "z-index": "100",
        top: "0",
        left: "0",
        visibility: "hidden"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-freshen",
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            height: "100vh",
            "justify-content": "center",
            "align-items": "center",
            "z-index": "99"
          }
        },
        [
          _c("div", { staticClass: "modal-freshen-main" }, [
            _c(
              "div",
              {
                staticClass: "moda-freshen-top",
                staticStyle: { display: "flex", "justify-content": "flex-end" }
              },
              [
                _c("p", { staticStyle: { cursor: "pointer" } }, [
                  _c("img", {
                    staticClass: "close-modal-freshen",
                    staticStyle: { width: "40px" },
                    attrs: {
                      src: "https://img.icons8.com/ios/50/000000/multiply.png"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModal()
                      }
                    }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-5" }, [
                _c("div", { staticClass: "modal-freshen-title-mobile" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "text-center",
                      staticStyle: { "font-size": "1.5em" }
                    },
                    [_vm._v("Depilação a laser")]
                  ),
                  _vm._v(" "),
                  _c(
                    "h3",
                    {
                      staticClass: "text-center",
                      staticStyle: {
                        "font-weight": "700",
                        "font-size": "1.5em"
                      }
                    },
                    [_vm._v(_vm._s(_vm.packageData.name))]
                  ),
                  _vm._v(" "),
                  _c("hr")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "flex-direction": "column",
                      "align-items": "center"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: { src: _vm.packageData.main_image, alt: "" }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "mb-2",
                        staticStyle: {
                          "background-color": "#eee",
                          border: "1px solid #3fb6a7",
                          "border-top": "1px solid #3fb6a7",
                          "border-radius": "0px 0px 10px 10px",
                          padding: "5px 15px"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "bi bi-info-circle",
                          staticStyle: { color: "#3fb6a7" }
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.packageData.tagline) +
                            "\n                  "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt-5 mb-4 beneficios" }, [
                      _c("span", [
                        _c(
                          "svg",
                          {
                            staticClass: "bi bi-coin",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "16",
                              height: "16",
                              fill: "#3fb6a7",
                              viewBox: "0 0 16 16"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                "fill-rule": "evenodd",
                                d:
                                  "M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              }
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                "fill-rule": "evenodd",
                                d:
                                  "M8 13.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
                              }
                            })
                          ]
                        ),
                        _vm._v(
                          "\n                      " +
                            _vm._s(
                              _vm.packageData.current_event_credit_quantity
                            ) +
                            " Créditos\n                    "
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _vm._m(1)
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "modal-freshen-collapse",
                        on: {
                          click: function($event) {
                            return _vm.changeGuidance()
                          }
                        }
                      },
                      [
                        _c("div", [
                          _c("span"),
                          _c("span", [_vm._v("Orientações")]),
                          _vm._v(" "),
                          _c("i", {
                            class: _vm.guidance
                              ? "bi bi-chevron-up"
                              : "bi bi-chevron-down"
                          })
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.guidance
                      ? _c(
                          "p",
                          {
                            staticClass: "text-left mt-2",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _c(
                              "ul",
                              {
                                staticClass: "list-tab",
                                staticStyle: { "list-style": "none" }
                              },
                              [
                                _c("li", [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    staticStyle: { width: "1em" },
                                    attrs: { src: _vm.razorIcon }
                                  }),
                                  _vm._v(
                                    "  Raspar os pelos da região a ser tratada 1 dia antes da sessão. Utilize lâmina ou creme depilatório."
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    staticStyle: { width: "1em" },
                                    attrs: { src: _vm.waxingIcon }
                                  }),
                                  _vm._v(
                                    "  Não utilizar métodos que arranquem o pelo como cera e pinça 20 dias antes da sessão."
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    staticStyle: { width: "1em" },
                                    attrs: { src: _vm.sunIcon }
                                  }),
                                  _vm._v(
                                    "  Evitar exposição solar na região a ser depilada por aproximadamente 7 dias antes e 3 dias depois da sessão."
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    staticStyle: { width: "1em" },
                                    attrs: { src: _vm.clockIcon }
                                  }),
                                  _vm._v(
                                    "  O intervalo entre as sessões varia de 45 a 90 dias."
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "modal-freshen-collapse",
                        on: {
                          click: function($event) {
                            return _vm.changeContraindication()
                          }
                        }
                      },
                      [
                        _c("div", [
                          _c("span"),
                          _c("span", [_vm._v("Contraindicações")]),
                          _vm._v(" "),
                          _c("i", {
                            class: _vm.contraindication
                              ? "bi bi-chevron-up"
                              : "bi bi-chevron-down"
                          })
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm.contraindication
                      ? _c(
                          "p",
                          {
                            staticClass: "text-left mt-2",
                            staticStyle: { width: "100%" }
                          },
                          [_vm._m(2)]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-freshen mt-3 mb-5",
                        on: {
                          click: function($event) {
                            return _vm.addCart(_vm.packageData.name)
                          }
                        }
                      },
                      [_vm._v("Adicionar")]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-7" }, [
                _c("div", { staticClass: "modal-freshen-title" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "text-center",
                      staticStyle: { "font-size": "2.5em" }
                    },
                    [_vm._v("Depilação a laser")]
                  ),
                  _vm._v(" "),
                  _c(
                    "h3",
                    {
                      staticClass: "text-center",
                      staticStyle: {
                        "font-weight": "700",
                        "font-size": "2.5em"
                      }
                    },
                    [_vm._v(_vm._s(_vm.packageData.name))]
                  ),
                  _vm._v(" "),
                  _c("hr")
                ]),
                _vm._v(" "),
                _c("h5", [
                  _vm._v(
                    "\n                  Pague de " +
                      _vm._s(
                        _vm.format_currency(
                          _vm.get_price(
                            _vm.packageData.current_event_credit_quantity,
                            _vm.packageData.price_sales_base
                          )
                        )
                      ) +
                      " \n                  "
                  ),
                  _c("small", [
                    _vm._v(
                      "(" +
                        _vm._s(
                          _vm.get_discount(
                            _vm.packageData.current_event_credit_quantity
                          ) * 100
                        ) +
                        "%OFF)"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("h3", [
                  _vm._v("Até "),
                  _c(
                    "span",
                    { staticStyle: { color: "#b66640", "font-size": "1.5em" } },
                    [
                      _vm._v(
                        "\n                  " +
                          _vm._s(
                            _vm.format_currency(
                              _vm.get_price_min(
                                _vm.packageData.current_event_credit_quantity,
                                _vm.packageData.price_sales_base
                              )
                            )
                          )
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "(" +
                        _vm._s(
                          _vm.get_discount_max(
                            _vm.packageData.current_event_credit_quantity
                          ) * 100
                        ) +
                        "%OFF)"
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "h5",
                  { staticStyle: { color: "#3fb6a7", "margin-bottom": "8px" } },
                  [_vm._v("Descontos progressivos")]
                ),
                _vm._v(" "),
                _c("table", { staticClass: "table table-striped" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "bg-cel-table-freshen",
                          staticStyle: { opacity: "0.5" }
                        },
                        [_vm._v("20%")]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            color: "#b66640",
                            "font-weight": "600"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.format_currency(
                                _vm.packageData.price_sales_base * (1 - 0.2)
                              )
                            )
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _vm._m(6),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "bg-cel-table-freshen",
                          staticStyle: { opacity: "0.6" }
                        },
                        [_vm._v("40%")]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            color: "#b66640",
                            "font-weight": "600"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.format_currency(
                                _vm.packageData.price_sales_base * (1 - 0.4)
                              )
                            )
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _vm._m(7),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "bg-cel-table-freshen",
                          staticStyle: { opacity: "0.7" }
                        },
                        [_vm._v("52%")]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            color: "#b66640",
                            "font-weight": "600"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.format_currency(
                                _vm.packageData.price_sales_base * (1 - 0.52)
                              )
                            )
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _vm._m(8),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "bg-cel-table-freshen",
                          staticStyle: { opacity: "0.8" }
                        },
                        [_vm._v("56%")]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            color: "#b66640",
                            "font-weight": "600"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.format_currency(
                                _vm.packageData.price_sales_base * (1 - 0.56)
                              )
                            )
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _vm._m(9),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "bg-cel-table-freshen",
                          staticStyle: { opacity: "0.9" }
                        },
                        [_vm._v("62%")]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            color: "#b66640",
                            "font-weight": "600"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.format_currency(
                                  _vm.packageData.price_sales_base * (1 - 0.62)
                                )
                              )
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _vm._m(10),
                      _vm._v(" "),
                      _c("td", { staticClass: "bg-cel-table-freshen" }, [
                        _vm._v("64%")
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            color: "#b66640",
                            "font-weight": "600"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.format_currency(
                                _vm.packageData.price_sales_base * (1 - 0.64)
                              )
                            )
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", {
        staticClass: "bi bi-box-seam",
        staticStyle: { color: "#3fb6a7" }
      }),
      _vm._v(" Pacote completo: 6 sessões")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", {
        staticClass: "bi bi-house-door",
        staticStyle: { color: "#3fb6a7" }
      }),
      _vm._v(" Sessões no conforto da sua casa")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      { staticClass: "list-tab", staticStyle: { "list-style": "none" } },
      [
        _c("li", [
          _c("i", { staticClass: "bi bi-exclamation-circle mr-2" }),
          _vm._v("Pelos brancos.")
        ]),
        _vm._v(" "),
        _c("li", [
          _c("i", { staticClass: "bi bi-exclamation-circle mr-2" }),
          _vm._v("Uso de Anabolizantes.")
        ]),
        _vm._v(" "),
        _c("li", [
          _c("i", { staticClass: "bi bi-exclamation-circle mr-2" }),
          _vm._v(" Radioterapia/Quimioterapia / Lesões Malignas.")
        ]),
        _vm._v(" "),
        _c("li", [
          _c("i", { staticClass: "bi bi-exclamation-circle mr-2" }),
          _vm._v(
            " Utilização de ROACUTAN (isotretinoína oral) a menos de 6 meses."
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("small", [
        _vm._v(
          "Basta adicionar mais regiões ao seu pacote para atingir as faixas de desconto progressivo, pagando até o valor mínimo por região de 64%OFF."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "bg-cel-table-freshen-header" }, [
        _c("th", [_vm._v("Créditos")]),
        _vm._v(" "),
        _c("th", [_vm._v("Desconto")]),
        _vm._v(" "),
        _c("th", [_vm._v("R$")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("small", [_vm._v("A partir de 30 Créditos")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("small", [_vm._v("A partir de 110 Créditos")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("small", [_vm._v("A partir de 170 Créditos")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("small", [_vm._v("A partir de 320 Créditos")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("small", [_vm._v("A partir de 470 Créditos")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [_c("small", [_vm._v("A partir de 600 Créditos")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }