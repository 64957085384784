<template>
    <div class="modal-freshen" style="background-color:#00000022; width:100vw; height:100vh; position:fixed; z-index:100; top:0; left:0; visibility:hidden;">
      <div style="display:flex; flex-direction:column; height:100vh; justify-content:center; align-items:center; z-index:99;" class="modal-freshen">
            <div class="modal-freshen-main">
                <div class="moda-freshen-top" style="display:flex;justify-content:flex-end;">
                  <p style="cursor:pointer;"><img class="close-modal-freshen" @click="closeModal()" style="width:40px;" src="https://img.icons8.com/ios/50/000000/multiply.png"/></p>
                </div>
                <div class="row">
                  <div class="col-md-5">
                    <div class="modal-freshen-title-mobile">
                      <h3 class="text-center" style="font-size:1.5em;">Depilação a laser</h3>
                      <h3 class="text-center" style="font-weight:700; font-size:1.5em;">{{packageData.name}}</h3>
                      <hr>
                    </div>
                    <div style="display:flex; flex-direction:column; align-items:center;">
                      <img class="img-fluid" :src="packageData.main_image" alt="">
                      <div class="mb-2" style="background-color:#eee; border:1px solid #3fb6a7; border-top:1px solid #3fb6a7; border-radius:0px 0px 10px 10px; padding:5px 15px;">
                        <i style="color:#3fb6a7;" class="bi bi-info-circle"></i> {{packageData.tagline}}
                      </div>

                      <div class="mt-5 mb-4 beneficios" >
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#3fb6a7" class="bi bi-coin" viewBox="0 0 16 16">
                            <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fill-rule="evenodd" d="M8 13.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
                          </svg>
                          {{packageData.current_event_credit_quantity}} Créditos
                        </span>
                        <span><i style="color:#3fb6a7;" class="bi bi-box-seam"></i> Pacote completo: 6 sessões</span>
                        <span><i style="color:#3fb6a7;" class="bi bi-house-door"></i> Sessões no conforto da sua casa</span>
                      </div>

                      <div class="modal-freshen-collapse" @click="changeGuidance()">
                        <div><span></span><span>Orientações</span> <i :class="guidance?'bi bi-chevron-up':'bi bi-chevron-down'"></i></div>   
                      </div>
                      <p v-if="guidance" class="text-left mt-2" style="width:100%;">
                          <ul class="list-tab" style="list-style:none;">
                            <li> <img style="width:1em;" class="mr-2" :src="razorIcon"/>  Raspar os pelos da região a ser tratada 1 dia antes da sessão. Utilize lâmina ou creme depilatório.</li>
                            <li> <img style="width:1em;" class="mr-2" :src="waxingIcon"/>  Não utilizar métodos que arranquem o pelo como cera e pinça 20 dias antes da sessão.</li>
                            <li> <img style="width:1em;" class="mr-2" :src="sunIcon"/>  Evitar exposição solar na região a ser depilada por aproximadamente 7 dias antes e 3 dias depois da sessão.</li>
                            <li> <img style="width:1em;" class="mr-2" :src="clockIcon"/>  O intervalo entre as sessões varia de 45 a 90 dias.</li>
                          </ul>
                      </p>
                      <div class="modal-freshen-collapse" @click="changeContraindication()">
                        <div><span></span><span>Contraindicações</span> <i :class="contraindication?'bi bi-chevron-up':'bi bi-chevron-down'"></i></div>   
                      </div>
                      <p v-if="contraindication" class="text-left mt-2" style="width:100%;">
                          <ul class="list-tab" style="list-style:none;">
                            <li> <i class="bi bi-exclamation-circle mr-2"></i>Pelos brancos.</li>
                            <li> <i class="bi bi-exclamation-circle mr-2"></i>Uso de Anabolizantes.</li>
                            <li> <i class="bi bi-exclamation-circle mr-2"></i> Radioterapia/Quimioterapia / Lesões Malignas.</li>
                            <li> <i class="bi bi-exclamation-circle mr-2"></i> Utilização de ROACUTAN (isotretinoína oral) a menos de 6 meses.</li>
                          </ul>
                      </p>

                      <button class="btn-freshen mt-3 mb-5" @click="addCart(packageData.name)">Adicionar</button>
                    </div>


                  </div>
                  <div class="col-md-7">
                    <div class="modal-freshen-title">
                      <h3 class="text-center" style="font-size:2.5em;">Depilação a laser</h3>
                      <h3 class="text-center" style="font-weight:700; font-size:2.5em;">{{packageData.name}}</h3>
                      <hr>
                    </div>
                    <h5>
                      Pague de {{format_currency(get_price(packageData.current_event_credit_quantity, packageData.price_sales_base)) }} 
                      <small>({{get_discount(packageData.current_event_credit_quantity)*100}}%OFF)</small>
                    </h5>
                    <h3>Até <span style="color:#b66640; font-size:1.5em;">
                      {{format_currency(get_price_min(packageData.current_event_credit_quantity, packageData.price_sales_base))}}</span> 
                      <small>({{get_discount_max(packageData.current_event_credit_quantity)*100}}%OFF)</small>
                    </h3>
                    <p><small>Basta adicionar mais regiões ao seu pacote para atingir as faixas de desconto progressivo, pagando até o valor mínimo por região de 64%OFF.</small></p>
                    
                    <h5 style="color:#3fb6a7; margin-bottom:8px;">Descontos progressivos</h5>
                    <table class="table table-striped">
                      <thead>
                        <tr class="bg-cel-table-freshen-header">
                          <th>Créditos</th>
                          <th>Desconto</th>
                          <th>R$</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><small>A partir de 30 Créditos</small></td>
                          <td style="opacity:0.5;" class="bg-cel-table-freshen">20%</td>
                          <td style="color:#b66640; font-weight:600;">{{format_currency(packageData.price_sales_base * (1 - 0.2))}}</td>
                        </tr>
                        <tr>
                          <td><small>A partir de 110 Créditos</small></td>
                          <td style="opacity:0.6;" class="bg-cel-table-freshen">40%</td>
                          <td style="color:#b66640; font-weight:600;">{{format_currency(packageData.price_sales_base * (1 - 0.4))}}</td>
                        </tr>
                        <tr>
                          <td><small>A partir de 170 Créditos</small></td>
                          <td style="opacity:0.7;" class="bg-cel-table-freshen">52%</td>
                          <td style="color:#b66640; font-weight:600;">{{format_currency(packageData.price_sales_base * (1 - 0.52))}}</td>
                        </tr>
                        <tr>
                          <td><small>A partir de 320 Créditos</small></td>
                          <td style="opacity:0.8;" class="bg-cel-table-freshen">56%</td>
                          <td style="color:#b66640; font-weight:600;">{{format_currency(packageData.price_sales_base * (1 - 0.56))}}</td>
                        </tr>
                        <tr>
                          <td><small>A partir de 470 Créditos</small></td>
                          <td style="opacity:0.9;" class="bg-cel-table-freshen">62%</td>
                          <td style="color:#b66640; font-weight:600;"> {{format_currency(packageData.price_sales_base * (1 - 0.62))}}</td>
                        </tr>
                        <tr>
                          <td><small>A partir de 600 Créditos</small></td>
                          <td class="bg-cel-table-freshen">64%</td>
                          <td style="color:#b66640; font-weight:600;">{{format_currency(packageData.price_sales_base * (1 - 0.64))}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- <p class="text-center mt-2 m-0 p-0">
                      <small>
                        * Parcelamento disponível em até 10x no cartão de crédito com parcela mínima de R$ 180,00
                      </small>
                    </p> -->
                  </div>
                </div>
                
            </div>
      </div>
    </div>
</template>

<script>
import RazorIcon from './images/razor-blade.png';
import ClockIcon from './images/clock.png';
import SunIcon from './images/sun.png';
import WaxingIcon from './images/waxing.png';
export default {
    name:'ModalPackageInfo',
    data(){
        return{
          packageData:{},
          guidance:false,
          contraindication:false,
          razorIcon:RazorIcon,
          clockIcon:ClockIcon,
          sunIcon:SunIcon,
          waxingIcon:WaxingIcon
        };
    },
    methods:{
        changeGuidance(){
          (this.guidance)? this.guidance = false: this.guidance = true;
          this.contraindication = false;
        },
        changeContraindication(){
          this.contraindication? this.contraindication = false: this.contraindication = true;
          this.guidance = false;
        },
        addCart(name){
            document.querySelectorAll('.ae-package-default-action-btn-cart').forEach((item, i)=>{
                if(item.textContent == name){
                document.querySelectorAll('.ae-package-default-action-btn-cart')[i].click();
                document.querySelector('.modal-freshen').style.visibility = 'hidden';
                document.querySelector('body').style.overflowY = 'visible';
                }
            });
        },
        get_discount(credits){
            if(credits >= 30 && credits <= 109) return 0.2;
            if(credits >= 110 && credits <= 169) return 0.4;
            if(credits >= 170 && credits <= 319) return 0.52;
            if(credits >= 320 && credits <= 469) return 0.56;
            if(credits >= 470 && credits <= 599) return 0.62;
            if(credits >= 600) return 0.64;
        },
        get_price(credits, price){
            //const credit_cost = 20;
            return price * (1 - this.get_discount(credits));
        },
        get_price_min(credits, price){
            //const credit_cost = 20;
            //credits
            return price * (1 - this.get_discount_max());

        },
        get_discount_max(){
            return 0.64;
        },
        format_currency(value){
            var formato = { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' };
            return value.toLocaleString('pt-BR', formato);
        },
        closeModal(){
            document.querySelector('.modal-freshen').style.visibility = 'hidden';
            document.querySelector('body').style.overflowY = 'visible';
        }
    },
     mounted(){
        window.addEventListener('infoEventPackage', (event)=>{
            document.querySelector('.modal-freshen').style.visibility = 'visible';
            document.querySelector('body').style.overflowY = 'hidden';
            this.packageData = event.detail;
        });
         
    }
};
</script>

<style scoped>
      .btn-freshen{
    width: 100% !important;
    border-radius: 30px !important;
    padding: 16px !important;
    font-weight: bold !important;
    color: #fff !important;
    background-color: #3fb6a7 !important;
    border: none !important;
    font-size: 18px !important;
  }
  .modal-freshen-title-mobile{
    display: none;
  }
  .modal-freshen-collapse{
    border: 1px solid #eee;
    border-radius: 2px;
    padding: 2px;
    width: 100%;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    padding:5px;
    cursor:pointer;
    margin-bottom: 5px;
    box-shadow: 1px 1px #eee;
  }

  .modal-freshen-collapse div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .beneficios{
    display:flex; 
    flex-direction:column;
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 50px;
  }
  .beneficios span{
    margin-bottom: 10px;
  }
  .table{
    width: 100%;
    text-align: center;
  }
  .bg-cel-table-freshen-header{
    background-color:#f2f2f2; 
  }
  .bg-cel-table-freshen{
    background-color:#3fb6a7; 
    color:white;
    font-size:1.7em;
    font-weight: 600;
  }

  .modal-freshen-main{
    max-height:95vh; 
    position:absolute; 
    width:70vw; 
    background-color:white; 
    border:1px solid #eee; 
    z-index:99;
    border-radius:5px; 
    padding:20px;
    padding-bottom:30px;
    overflow-y: auto;
  }

  th, td{
    vertical-align: inherit !important;
  }

  .list-tab{
    padding: 0;
    margin: 0;
  }


  .list-tab li{
    padding: 0.5em;
  }

  .list-tab li:nth-child(1){
    background-color: #98d4cc47;
    border-radius: 5px 5px 0px 0px;
  }
  .list-tab li:nth-child(2){
    background-color: #eeeeee42;
  }
  .list-tab li:nth-child(3){
    background-color: #98d4cc47;
  }
  .list-tab li:nth-child(4){
    background-color: #eeeeee42;
    border-radius: 0px 0px 5px 5px;
  }

  .list-tab li i{
    color:#3fb6a7;
    font-weight: bold;
  }


  

  @media (min-width: 769px) and (max-width: 1076px){
    .modal-freshen-main{
      width:90vw; 
      margin-top:6vh; 
    }
  }

  @media (max-width: 768px) {
    .modal-freshen-main{
      width:90vw; 
      margin-top:2vh; 
    }
    .modal-freshen-title-mobile{
      display: block;
    }
    .modal-freshen-title{
      display: none;
    }
    .bg-cel-table-freshen{
      background-color:#3fb6a7; 
      color:white;
      font-size:1.1em;
      font-weight: 600;
    }
    thead{
      font-size: 0.8em;
    }
    table{
     font-size: 0.9em; 
    }
  }
</style>