<template>
  <div class="w-100">
    <div class="genre-selector-title text-center mb-2">Depilação para</div>
    <div class="d-flex justify-content-center">
      <button
        class="btn m-1"
        :class="{ 
            'btn-primary': selected === FEMALE_TAG, 
            'btn-outline-primary': selected !== FEMALE_TAG 
        }"
        @click="onSelect(FEMALE_TAG)"
      >Mulher</button>
      <button
        class="btn m-1"
        :class="{ 
            'btn-primary': selected == MALE_TAG, 
            'btn-outline-primary': selected !== MALE_TAG 
        }"
        @click="onSelect(MALE_TAG)"
      >Homem</button>
    </div>

    <slot :genre="selected" />
  </div>
</template>

<style lang="scss">
.genre-selector-title {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
</style>

<script>
const MALE_TAG = 'masculino';
const FEMALE_TAG = 'feminino';
const STORAGE_KEY = 'genre-selector';

export default {
  data() {
    return {
      FEMALE_TAG,
      MALE_TAG,
      selected: FEMALE_TAG
    };
  },

  created() {
    const genre = window.localStorage.getItem(STORAGE_KEY);

    if (genre) {
      this.onSelect(genre);
    }
  },

  methods: {
    onSelect(genre) {
      window.localStorage.setItem(STORAGE_KEY, genre);
      this.selected = genre;
    }
  }
};
</script>