<template>
  <div @click="insertLocal(local.id)" class="card card-rounded" style="width: 18rem">
    <div v-if="check" class="selected d-flex justify-content-center align-items-center">
      <span style="color: #26ad5e">
        <i class="fas fa-check fa-3x"></i>
      </span>
    </div>
    <img
      :src="
        local.image || 'https://www.inovegas.com.br/site/wp-content/uploads/2017/08/sem-foto.jpg'
      "
      class="card-img-top"
    />
    <div class="card-body">
      <div class="card-local-title mb-0 text-center">{{ local.name }}</div>
      <p>{{ local.duration_time }} min de duração</p>
      <p> {{ local.credit_quantity > 1 ? `Créditos: ${local.credit_quantity}` : `Crédito: ${local.credit_quantity}`}}</p>
    </div>
  </div>
</template>

<script>
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
//import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'card-local',
  props: {
    local: String,
    check: Boolean,
  },
  components: {
    //FontAwesomeIcon,
  },
  data() {
    return {};
  },
  methods: {
    insertLocal(val) {
      this.$emit('insertLocal', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './scss/cardBodyArea.scss';
</style>
