<template>
  <div class="d-flex flex-column align-items-center">
    <div class="pos-selector-title mb-2">Selecione a unidade</div>

    <div
      class="pos-selector-subtitle mb-4"
    >Antes visualizar os pacotes, informe onde você deseja realizar nossos serviços.</div>

    <div class="d-flex flex-wrap justify-content-center">
      <template v-for="(item, key) in posList">
        <button
          :key="key"
          class="btn m-1"
          :class="{ 
            'btn-primary': isSelected(item.id),
            'btn-outline-primary': !isSelected(item.id)
        }"
          @click="onSelect(item.id)"
        >{{ item.short_description }}</button>
      </template>
    </div>

    <template v-if="posIdSelected">
      <slot :posId="posIdSelected" />
    </template>
  </div>
</template>

<style lang="scss">
.pos-selector-title {
  font-size: 30px;
  color: #000;
  font-weight: bold;
  text-align: center;
}

.pos-selector-subtitle {
  font-size: 16px;
  color: rgba(136, 136, 136, 1);
  text-align: center;
}
</style>

<script>
const STORAGE_KEY = 'pos-selector';

export default {
  data() {
    return {
      posList: [],
      posIdSelected: null,
    };
  },

  created() {
    let posId = undefined;
    if(this.getPosUrl()){
      posId = this.getPosUrl();
    }else{
      posId = window.localStorage.getItem(STORAGE_KEY);
    }

    if (posId) {
      this.onSelect(Number(posId));
    }

    this.getPOS().then((list) => {
      this.posList = list;
    });
  },
  methods: {
    getPosUrl(){
      const url = new URLSearchParams(window.location.search);
      return url.get('pos');
    },
    isSelected(posId) {
      return posId === this.posIdSelected;
    },
    onSelect(posId) {
      if (this.isSelected(posId)) {
        return;
      }

      this.setPOS(posId).then(() => {
        window.localStorage.setItem(STORAGE_KEY, posId);
        this.posIdSelected = Number(posId);
      });
    },
    setPOS(posId) {
      posId = Number(posId);

      return window.AngularEcommerce.ws.checkout.get().then(({ data }) => {
        if (data.pos_id === posId) {
          return Promise.resolve();
        }

        return window.AngularEcommerce.ws.checkout.setPOS('current', { point_of_sale: posId });
      });
    },
    getPOS() {
      return new Promise((resolve) => {
        const results = [];

        const paginator = window.AngularEcommerce.ws.pointsOfSale.getAll({
          params: {
            is_online: true,
          },
        });

        paginator.getAllPages(({ data }) => {
          results.push(...data.results);

          if (!paginator.hasNextPage()) {
            resolve(results);
          }
        });
      });
    },
  },
};
</script>
