<template>
  <ae-package-list
    v-if="canRender"
    :template.prop="'pacotes'"
    :posId.prop="config.posId"
    :params.prop="params"
  ></ae-package-list>
</template>

<style lang="scss">
.ae-package-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;

  .ae-package-container {
    margin: 30px 10px;
  }
}

.ae-package-default {
  box-shadow: 0 5px 15px #0000001a;
  border-radius: 8px !important;
  background: #fff !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  max-width: 272px !important;
  width: 272px !important;
  padding: 0 !important;
}

@media screen and (max-width: 400px) {
  .ae-package {
    width: 100% !important;
  }

  .ae-package-default {
    border: 1px solid #949494bd;
    box-shadow: none;
    width: 100% !important;
  }

  .ae-truncate.ae-package-default-title {
    height: fit-content;
    max-width: 170px;
    font-size: 18px !important;
  }

  .ae-package-default-price {
    padding: 0 !important;
  }

  .ae-package-default-price b {
    font-size: 22px !important;
  }
}

.ae-package-default img {
  width: 100% !important;
}
.ae-package-default .ae-package-default-truncate {
  display: block !important;
  max-width: 250px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.ae-package-default .ae-package-default-title {
  font-size: 27px;
  font-weight: bold !important;
  color: #555 !important;
  margin-top: 16px !important;
  color: black !important;
}
.ae-package-default .ae-package-default-subtitle {
  font-size: 18px;
  color: #555 !important;
  min-height: 29px !important;
  margin-bottom: 8px !important;
}
.ae-package-default .ae-package-default-price-with-discount {
  display: block !important;
}
.ae-package-default .ae-package-default-price,
.ae-package-default .ae-package-default-recurrency-price-info {
  font-size: 16px !important;
  color: #555 !important;
  text-align: center !important;
  min-height: 48px !important;
  width: 100% !important;
  padding: 0 24px;
}
.ae-package-default div.ae-package-default-price > span {
  color: #acacac !important;
}
.ae-package-default b {
  font-size: 18px !important;
  color: #ff7320 !important;
}
.ae-package-default .ae-package-default-divider {
  height: 1px !important;
  width: 100% !important;
  background: #0000001a !important;
  margin: 0 auto 16px !important;
  width: 80% !important;
}
.ae-package-default .ae-package-default-credit-info {
  display: flex !important;
  margin-bottom: 16px !important;
  width: 100% !important;
  max-width: 250px !important;
  color: #666 !important;
}
.ae-package-default .ae-package-default-credit-info .ae-package-default-credit-info-item {
  flex: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.ae-package-default
  .ae-package-default-credit-info
  .ae-package-default-credit-info-item.ae-package-default-credit-info-item-right {
  justify-content: flex-end !important;
}
.ae-package-default-price,
.ae-package-default-recurrency-price-info {
  width: 100% !important;
  padding: 0 24px 12px;
}
.ae-package-default .ae-package-default-credit-info .ae-package-default-credit-info-item i {
  margin-right: 8px !important;
}
.ae-package-default .ae-package-default-transferable,
.ae-package-default .ae-package-default-validity,
.ae-package-default .ae-package-default-shareable {
  color: #666 !important;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 16px !important;
  justify-content: center !important;
}
.ae-package-default .ae-package-default-transferable i,
.ae-package-default .ae-package-default-validity i,
.ae-package-default .ae-package-default-shareable i {
  margin-right: 8px !important;
}
.ae-package-default .ae-package-default-action-btn {
  width: 100% !important;
  border-radius: 30px !important;
  padding: 16px !important;
  font-weight: bold !important;
  color: #fff !important;
  background-color: #3fb6a7 !important;
  border: none !important;
  font-size: 18px !important;
}
.ae-package-default-price-with-discount {
  text-decoration: none !important;
}
.ae-package-default .ae-package-default-action-btn:hover {
  background-color: #3fb6a7 !important;
}
.btn.btn-outline-primary.ae-package-default-action-btn.my-1,
.btn.btn-outline-danger.ae-package-default-action-btn.my-1 {
  max-width: 90% !important;
  margin-bottom: 16px !important;
}
.btn.btn-outline-danger.ae-package-default-action-btn.my-1 {
  color: #dc3545 !important;
  border: 2px solid #dc3545 !important;
  background-color: #fff !important;
}

.session-info > span:nth-child(3) {
  font-size: 18px !important;
  color: #555 !important;
}

.session-info {
  margin-bottom: 16px;
  text-align: center !important;
}
</style>

<script>
export default {
  props: {
    config: Object
  },

  data() {
    return {
      canRender: false,
      params: {}
    };
  },

  watch: {
    config: {
      handler() {
        this.canRender = false;

        const { posId, tag } = this.config;

        this.params = {
          pos_list: posId,
          tag
        };

        setTimeout(() => {
          this.canRender = true;
        });
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
