import { render, staticRenderFns } from "./modal-package-info.vue?vue&type=template&id=83618012&scoped=true&"
import script from "./modal-package-info.vue?vue&type=script&lang=js&"
export * from "./modal-package-info.vue?vue&type=script&lang=js&"
import style0 from "./modal-package-info.vue?vue&type=style&index=0&id=83618012&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83618012",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/angular-ecommerce/frontend/duda-projects/freshen/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('83618012')) {
      api.createRecord('83618012', component.options)
    } else {
      api.reload('83618012', component.options)
    }
    module.hot.accept("./modal-package-info.vue?vue&type=template&id=83618012&scoped=true&", function () {
      api.rerender('83618012', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/packages/modal-package-info.vue"
export default component.exports