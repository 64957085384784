<template>
  <div>
    <div class="card cardEvent">
      <div class="d-flex justify-content-between text-secondary align-items-center mt-2 pr-4 pl-4">
        <div class="title-card">
          {{ data.name }}
          
          <div v-if="data.description || data.tagline" class="popover__wrapper">
            <span>
              <i class="bi bi-info-circle popover__title"></i>
            </span>
            <div class="popover__content">
              <p class="popover__message m-0" v-html="data.description || data.tagline"></p>
            </div>
          </div>
        </div>
        <div>{{ returnHors(data.start_time) }} às {{ returnHors(data.end_time) }}</div>
      </div>
      <div class="divider"></div>
      <div class="mt-2 pl-4 pr-4">
        <p v-if="data && data.free_time.length > 0" class="card-text text-secondary">Horários livres em {{ returnDate() }}</p>
        <p v-else class="card-text text-secondary">{{ returnDate() }}</p>

        <div v-if="data && data.free_time.length > 0">
          <div v-for="(item, i) in data.free_time" :key="`free-time${i}`">
            <div class="d-flex justify-content-between text-secondary align-items-center">
              <div class="text-left text-secondary">
                Das {{ returnHorsFormat(item.time_from) }} às {{ returnHorsFormat(item.time_to) }}
              </div>
              <div
                @click="openModal({ time_from: item.time_from, time_to: item.time_to })"
                class="btn-to-schedule"
              >
                Agendar
              </div>
            </div>
            <div v-if="i > data.free_time.length + 1" class="divider-small"></div>
          </div>
        </div>

        <div v-else class="d-flex justify-content-between text-secondary align-items-center">
          <div class="text-left text-secondary">Não há horários disponíveis!</div>
          <div
            class="btn-to-schedule-disabled"
          >
            Agendar
          </div>
        </div>
      </div>
    </div>

    <schedule-modal
      @closeModal="closeModal"
      @reload="reload"
      :locals="bodyAreas"
      :free_time="current_free_time"
      :eventGenerator="{ ...data, date_select: this.date }"
      v-if="modalOn"
    />
  </div>
</template>
<script>
//style card
import './scss/styleCard.scss';
import './scss/stylePopover.scss';

import ScheduleModal from '../scheduleModal/ScheduleModal.vue';

import moment from 'moment';
export default {
  name: 'event-generation',
  components: {
    ScheduleModal
  },
  props: {
    date: {
      type: Date,
      default: new Date()
    },
    data: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  data() {
    return {
      modalOn: false,
      locals: ['Panturrilha', 'virilha', 'costas'],
      current_free_time: {},
      bodyAreas: []
    };
  },
  methods: {
    reload() {
      this.$emit('reload');
    },
    closeModal(val) {
      this.locals = [];
      this.modalOn = val;
      this.step = 1;
    },
    returnHors(h) {
      //return h.slice(0, 5);
      return moment(h).format('HH:mm');
    },
    returnHorsFormat(h) {
      return h.slice(0, 5);
    },
    returnDate() {
      return moment(this.date).format('DD/MM/YYYY');
    },

    openModal(item) {
      if (window.localStorage.getItem('web-authorization')) {
        this.current_free_time = item;
        this.$emit('isLoadingStatus', true);
        this.loading = true;
        return new Promise(resolve => {
          let results = [];
          const paginator = window.AngularEcommerce.ws.events.getAllTemplate({
            params: {
              //event_generator: this.data.token,
              //unit_list: window.localStorage.getItem('unit_id'),
              //activity_list: 2,
              //room_list: '1,3,5',
              id_list: this.data.templates_service.join(','),
              active: true
            }
          });

          paginator.getAllPages(({ data }) => {
            results.push(...data.results);

            if (!paginator.hasNextPage()) {
              resolve(results);

              this.bodyAreas = results;
              this.$emit('isLoadingStatus', false);
              this.modalOn = true;
            }
          });
        });
      } else {
        window.AngularEcommerce.DudaWidgets.fns.showLoginModal();
        window.addEventListener('showLogin', () => {
          window.AngularEcommerce.ws.setAuthToken(window.localStorage.getItem('web-authorization'));
        });
      }
    }
  }
};
</script>
