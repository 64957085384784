var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card card-rounded",
      staticStyle: { width: "18rem" },
      on: {
        click: function($event) {
          return _vm.insertLocal(_vm.local.id)
        }
      }
    },
    [
      _vm.check
        ? _c(
            "div",
            {
              staticClass:
                "selected d-flex justify-content-center align-items-center"
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("img", {
        staticClass: "card-img-top",
        attrs: {
          src:
            _vm.local.image ||
            "https://www.inovegas.com.br/site/wp-content/uploads/2017/08/sem-foto.jpg"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "card-local-title mb-0 text-center" }, [
          _vm._v(_vm._s(_vm.local.name))
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.local.duration_time) + " min de duração")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm.local.credit_quantity > 1
                  ? "Créditos: " + _vm.local.credit_quantity
                  : "Crédito: " + _vm.local.credit_quantity
              )
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { color: "#26ad5e" } }, [
      _c("i", { staticClass: "fas fa-check fa-3x" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }