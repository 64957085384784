import { isDone } from './is-done';


export function createVueComponent(elementId, componentOptions) {
    isDone().then(() => {
        new window.Vue({
            el: `#${elementId}`,
            render: h => h(componentOptions)
        });
    });
}