<template>
  <calendar-monthly/>
</template>

<script>
import CalendarMonthly from './calendar-monthly.vue';

export default {
  components: {
    CalendarMonthly
  },
  mounted() {
    if(window.localStorage.getItem('web-authorization'))
      window.AngularEcommerce.ws.setAuthToken(window.localStorage.getItem('web-authorization'));
  },
};
</script>